/*eslint-disable react/jsx-filename-extension */
import { graphql } from "gatsby";
import { withPrefix } from "gatsby-link";
import { getUserLangKey } from "ptz-i18n";
import React from "react";

class RedirectIndex extends React.PureComponent {
  constructor(args) {
    super(args);

    // Skip build, Browsers only
    if (typeof window !== "undefined") {
      const { langs, defaultLangKey } = args.data.site.siteMetadata.languages;
      const langKey = getUserLangKey(langs, defaultLangKey);
      const homeUrl = withPrefix(`/${langKey}/`);

      window.location.replace(homeUrl + window.location.search);
    }
  }

  render() {
    return <div />;
  }
}

export default RedirectIndex;

export const pageQuery = graphql`
  query IndexQuery {
    site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
  }
`;
